import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Hero from "Layout/Hero/Hero"
import MainLayout from "Layout/layout";
import TabAccordion from 'Layout/TabAccordion/TabAccordion';
import Footer from "Layout/Footer/Footer";
import ContactForm from "Layout/Forms/ContactForm/ContactForm";
import config from "data/SiteConfig";

const Faq = (props) => {
  const { location, pageContext, data } = props;
  const { page, optionsPage } = data;

  return (
    <MainLayout location={location} pageContext={pageContext}>
      <Helmet
        meta={[
          {
            property: 'og:title',
            content: page.acf.meta_info_title,
          },
          {
            property: 'og:description',
            content: page.acf.meta_info_description,
          },
          {
            property: 'og:image',
            content: page.acf.social_share_image.image.localFile.childImageSharp.fluid.src,
          },
          {
            property: 'og:url',
            content: location.href,
          },
          {
            property: 'og:site_name',
            content: config.siteTitle,
          },
          {
            name: 'twitter:image:alt',
            content: page.acf.meta_info_description,
          }
        ]}
      />
      <Hero
        headline={page.acf.hero.headline}
        text={page.acf.hero.subheadline}
        openingTimes={optionsPage.edges[0].node.options.opening_times}
        waitingTime={optionsPage.edges[0].node.options.news.current_waiting_time}
      />
      <TabAccordion
        headline={page.acf.tab.tabs_headline}
        tabs={page.acf.tab.tabs}
      />
      <ContactForm headline={page.acf.contact.headline} formClasses="mt-4" />
      <Footer />
    </MainLayout>
  );
}

export const query = graphql`
  query FaqPageQuery($id: String!) {
    page: wordpressPage(id: { eq: $id }) {
      title
      slug
      acf {
        hero {
          headline
          subheadline
        }
        contact {
          headline
        }
        tab {
          tabs_headline
          tabs {
            title
            is_accordion
            accordion_items {
              question
              answer
            }
            list_items {
              item_headline
              item_description
            }
          }
        }
        social_share_image {
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
    optionsPage: allWordpressAcfOptions {
      edges {
        node {
          options {
            opening_times {
              opening_times_headline
              opening_times_additional_notice
              opening_times {
                opening_time_item
              }
            }
            news {
              current_waiting_time
            }
          }
        }
      }
    }
  }
`;

export default Faq
